// src/App.js
import React, { useState } from 'react';
import Card from './Card';
import Header from './Header';
import './App.css';

const data = [
  {
    title: 'Pier Two',
    description: 'Pier Two is a leading node operator',
    type: 'Operator',
  },  
  {
    title: 'Matrix.Linked',
    description: 'Pier Two is a leading node operator',
    type: 'Operator',
  },
  {
    title: 'Rome',
    description: 'Rome is a novel shared sequencer service and layer two that uses the Solana L1 natively',
    type: 'Project',
  },
  {
    title: 'Figment',
    description: 'Figment is a leading institutional staking provider.',
    type: 'Operator',
  },
  {
    title: 'Karak',
    description: 'Karak is a provider that enables credible commitments via restake',
    type: 'Project',
  },
  {
    title: 'Axelar',
    description: 'Axelar is the multichain platform that unifies Web3 for developers, delivering applications from any blockchain to every user, easily.',
    type: 'Project',
  },
  {
    title: 'Polymer',
    description: 'Polymer connects all Ethereum rollups with real-time high throughput interoperability, enabling swift cost-effective scaling for applications',
    type: 'Project',
  },
  {
    title: 'Wormhole',
    description: 'An Interoperability Platform Powering Multichain Apps and Bridges. Application layer. Connect. Integrate an in-app bridging widget in 3 lines of code.',
    type: 'Project',
  },
  {
    title: 'Hyperlane',
    description: 'Hyperlane is the open interoperability framework for blockchain smart contract devs and users',
    type: 'Project',
  },
  {
    title: 'Facet',
    description: 'Facet is the first sovereign based rollup. It is the only Ethereum scaling solution that doesn’t sacrifice security or decentralization.',
    type: 'Project',
  },
  {
    title: 'Commit-Boost',
    description: 'Commit-Boost is a new Ethereum validator sidecar focused on standardizing the last mile of communication between validators and third-party protocols.',
    type: 'Project',
  },
  {
    title: 'Fleek',
    description: 'Fleek Network is an open-source edge computing platform for accelerating the development and execution of decentralized web services.',
    type: 'Project',
  },  
  {
    title: 'Avail',
    description: 'Avail is a Web3 infrastructure layer that allows modular execution layers to scale and interoperate in a trust minimized way.',
    type: 'Project',
  }, 
  {
    title: 'Crossmint',
    description: 'A fully integrated suite of APIs · To build any blockchain use case · Make the blockchain invisible. For you and your users.',
    type: 'Project',
  }, 
  {
    title: 'Infinex',
    description: 'Your Infinex Account is the easiest way to access the world of DeFi with robust multi-chain support, and unrivalled, non-custodial security.',
    type: 'Project',
  }, 
  {
    title: 'Rift Exchange',
    description: 'Rift is the first trustless Bitcoin exchange. It allows anyone to trade assets between Bitcoin and any chain with extremely low fees and no middle men.',
    type: 'Project',
  },
  // Add more data as needed
];

function App() {
  const [filter, setFilter] = useState('All');

  const filteredData = filter === 'All' ? data : data.filter(item => item.type === filter);


  return (
    <div className="app">
      <a href="https://www.interstate.so">
        <img 
          src="/../equinox-logo-action.svg" 
          alt="Interstate Logo" 
          style={{ 
            position: 'absolute',
            top: '20px',
            left: '20px',
            height: '40px',
            cursor: 'pointer'
          }}
        />
      </a>
      {/* <Header /> */}

      {/* <h1>Interstate Ecosystem</h1> */}
      
      <div className="header-container">
      <p style={{ color: 'white' }}>
        An overview of the world-class applications, protocols, and networks in the Interstate ecosystem.
      </p>



      <div className="filter-buttons">
        <button onClick={() => setFilter('All')}>All</button>
        <button onClick={() => setFilter('Operator')}>Operators</button>
        <button onClick={() => setFilter('Project')}>Projects</button>
      </div>
      <div className="card-container">
        {filteredData.map((item, index) => (
          <Card key={index} {...item}  />
        ))}
      </div>
      </div>
    </div>
  );
}

export default App;
import React from 'react';
import './Card.css';
import img from "./download.png";

function Card({ title, description, type }) {
  const handleCardClick = () => {
    window.location.href = 'https://ethereum.org'; // Redirect to Ethereum's website
  };

  return (
    <div className="card" onClick={()=>handleCardClick()}>
      <div className="card-header">
        <img src={img} alt="icon" className="card-image" />
        <div className="card-title-container">
          <h3>{title}</h3>
          <span className="card-type">{type}</span>
        </div>
      </div>
      <p>{description}</p>
    </div>
  );
}

export default Card;
